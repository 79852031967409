<template>
  <div class="">
    <b-card class="">
      <div class="mb-1 d-flex justify-content-between">
        <strong>
          FAQs
        </strong>
        <hwa-button
          v-if="$can('create-faq', 'hwa')"
          icon="PlusIcon"
          label="Create FAQ"
          @onClick="openFaqModal()"
        />
      </div>
      <b-table
        v-if="$can('view-faqs', 'hwa')"
        small
        :fields="fields"
        :items="faqs"
        responsive="sm"
        selectable
        select-mode="single"
        @row-selected="handleRowClick"
      >
        <!-- <template #cell(action)="data">
          <hwa-button
            v-if="$can('delete-faq', 'hwa')"
            icon="XIcon"
            :icon-only="true"
            variant="flat-danger"
            @onClick="deleteFaq(data.item)"
          />
          <hwa-button
            v-if="$can('update-faq', 'hwa')"
            icon="EditIcon"
            :icon-only="true"
            variant="flat-success"
            @onClick="showUpdateForm(data.item)"
          />
        </template> -->
        <template #cell(roles)="data">
          <div
            v-for="(role,index) in data.item.roles"
            :key="index"
            class="px-1"
          >
            <b-badge variant="light-success">
              {{ role.name }}
            </b-badge>
          </div>
        </template>
      </b-table>
    </b-card>
    <validation-observer
      ref="faqForm"
      v-slot="{invalid}"
    >
      <hwa-modal
        :title="`${updateFaqMode?'Update':'Create'} FAQ`"
        :show="faqModalOpened"
        hide-footer
        :is_data_change="is_faq_change"
        size="lg"
        @onClose="closeFaqModal()"
      >
        <validation-provider
          #default="{ errors }"
          name="Question"
          rules="required"
        >
          <hwa-input
            v-model="faq.question"
            name="question"
            label="Question"
            placeholder="What is the question?"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Answer"
          rules="required"
        >
          <hwa-text-area
            v-model="faq.answer"
            name="answer"
            label="Answer"
            placeholder="What is the answer?"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <div class="d-flex align-items-center justify-content-between mt-2">
          <hwa-button
            variant="secondary"
            class="mr-1"
            label="Cancel"
            :class="is_faq_change ? 'active-secondary' : ''"
            @onClick="closeFaqModal()"
          />
          <hwa-button
            v-if="updateFaqMode"
            variant="danger"
            icon="XIcon"
            class="mr-1"
            label="Delete"
            @onClick="deleteFaq()"
          />
          <hwa-button
            :label="updateFaqMode?'Save Changes':'Save'"
            :class="is_faq_change === true ? 'active-success' : ''"
            :disable="invalid || is_faq_change === false ? true : false"
            :loading="faqLoading"
            loading-text="Loading..."
            @onClick="updateFaqMode?updateFaq():createFaq()"
          />
        </div>
      </hwa-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BTable, BCard, BBadge, BRow,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaModal from '@/layouts/components/HwaModal.vue'
import confirm from '@/mixins/confirm'
import HwaTextArea from '@/layouts/components/HwaTextArea'

export default {
  components: {
    HwaTextArea,
    HwaModal,
    HwaInput,
    HwaButton,
    BTable,
    BCard,
    BBadge,
    BRow,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      fields: [{ key: 'question', label: 'Question', sortable: true }, { key: 'answer', label: 'Answer', sortable: true }],
      faqs: [],
      faqLoading: false,
      updateFaqMode: false,
      faqModalOpened: false,
      faq: {
        id: null,
        question: null,
        answer: null,
      },
      is_faq_change: false,
    }
  },
  watch: {
    faq: {
      handler() {
        this.is_faq_change = true
      },
      deep: true,
    },
  },
  mounted() {
    this.getFaqs()
  },
  methods: {
    handleRowClick(row) {
      if (row['0']) {
        this.faq = { ...row['0'] }
        this.updateFaqMode = true
        this.faqModalOpened = true
        const dom = this
        setTimeout(() => {
          dom.is_faq_change = false
        }, 100)
      }
    },
    showUpdateForm(faq) {
      this.faq = { ...faq }
      this.updateFaqMode = true
      this.faqModalOpened = true
    },
    openFaqModal() {
      this.updateFaqMode = false
      this.faqModalOpened = true
    },
    closeFaqModal() {
      if (this.is_faq_change) {
        this.$swal({
          title: 'Do you want to save changes?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save changes!',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.faqModalOpened = false
            this.is_faq_change = false

            this.updateFaq()
          } else if (result.dismiss === 'cancel') {
            this.faqModalOpened = false
            this.is_faq_change = false
            this.emptyData()
          }
        })
      } else {
        this.faqModalOpened = false
        this.is_faq_change = false
      }
    },
    deleteFaq() {
      const { faq } = this
      this.faqLoading = true
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.$http.delete(`/api/faqs/${faq.id}`)
          .then(res => {
            this.faqLoading = false
            this.faqs = this.faqs.filter(f => +f.id !== faq.id)
            this.emptyData()
            this.faqModalOpened = false
            this.showSuccess('Success')
          })
          .catch(err => {
            this.faqLoading = false
            this.showError('Failed to delete faqs')
          })
      })
    },
    emptyData() {
      this.faq = { question: null, answer: null }
      const dom = this
      setTimeout(() => {
        dom.is_faq_change = false
      }, 100)
    },
    updateFaq() {
      // if (this.faq_id) {
      this.faqLoading = true
      this.$http.put(`/api/faqs/${this.faq.id}`, this.faq)
        .then(res => {
          this.faqLoading = false
          this.faqs = this.faqs.map(fa => {
            if (+fa.id === +this.faq.id) {
              return res.data
            }
            return fa
          })
          this.faqModalOpened = false
          this.is_faq_change = false
          this.showSuccess('Success')
          const dom = this
          setTimeout(() => {
            dom.emptyData()
          }, 100)
        })
        .catch(err => {
          this.faqLoading = false
          this.showError('Failed to update faq')
        })
    },
    createFaq() {
      this.faqLoading = true
      this.is_faq_change = false
      this.$http.post('/api/faqs', this.faq)
        .then(res => {
          this.faqLoading = false
          this.faqs = [res.data, ...this.faqs]
          this.$refs.faqForm.reset()
          this.closeFaqModal()
          this.emptyData()
          this.showSuccess('Success')
        })
        .catch(err => {
          this.faqLoading = false
          this.showError('Failed to create faqs')
        })
    },
    getFaqs() {
      this.$http.get('/api/faqs')
        .then(res => {
          this.faqs = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve faqs')
        })
    },
  },
}
</script>
